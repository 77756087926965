<script>
export default {
  props: {
    autosave: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "id",
    },
  },
};
</script>