<template>
  <DefaultSelect
    label="Rodzaj identyfikatora"
    :value.sync="internalValue"
    :items="items"
    :hideDetails="hideDetails"
    :required="required"
    :autosave="autosave"
    :syncId="syncId"
  ></DefaultSelect>
</template>

<script>
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin"

export default {
  mixins: [ProxyModificationMixin],
  props: {
    value: {},
    hideOptional: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    items() {
      return [
        {
          value: "K",
          text: "Krajowe identyfikatory osób w państwach UE i strefy Schengen",
        },
        { value: "P", text: "Numer paszportu" },
        {
          value: "DO",
          text: "Numery dowodów osobistych w państwach UE i strefy Schengen",
        },
        {
          value: "PJ",
          text: "Numery praw jazdy w państwach UE i strefy Schengen",
        },
        {
          value: "KZ",
          text: "Numery książeczek żeglarskich",
        },
        {
          value: "KT",
          text: "Krajowe tymczasowe identyfikatory osób w państwach UE i strefy Schengen",
        },
        {
          value: "KP",
          text: "Karta Polaka",
        },
        {
          value: "ZU",
          text: "Zaświadczenie Uchodźcy",
        },
        {
          value: "M",
          text: "Matka noworodka (PESEL)",
          visible: !this.hideOptional,
        },
        { value: "W", text: "Inny", visible: !this.hideOptional },
      ].filter((item) => item.visible === undefined || item.visible === true);
    },
  },
  components: {
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
  },
};
</script>